/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap"); */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

li {
  list-style: none;
}



/* HEADER */

header {
  display: flex;
  justify-content: space-between;
  background: #0d1b2a;
  color: #fff;
  width: 100%;
  height: 80px;
  align-items: flex-end;
}

/* EDWARD CHANG */

.header h1 {
  margin: 15px;
  color: white;
  font-size: 3rem;
}

/* NAVBAR  */

.nav-item a {
  color: white;
}
.nav-item a :hover {
  color: rgb(95, 95, 95);
}

.nav_list {
  display: flex;
  gap: 20px;
}

.nav_list li {
  cursor: pointer;
  border-bottom: 1px solid transparent;
}

.nav_list li.active {
  border-bottom: 1px solid #fff;
}

.nav_list li:hover {
  border-bottom: 1px solid #fff;
}

/* MAIN */

main {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  padding: 0 30px;
}

/* THIS PUSHES THE FOOTER TO THE BOTTOM */

.mainContainer {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #e0e1dd;
}

/* PROJECT */
.projectContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.projectTitle {
  font-size: 1.2rem;
}

.project {
  display: flex;
  flex-direction: column;
  height: 190px;
  overflow: hidden;
  border-radius: 6px;
}
.projectImage {
  height: 25%;
  width: 25%;
}

.project1container {
  display: flex;
  margin: 1rem;
  flex-direction: column;
}

.project_links {
  display: flex;
  gap: 20px;
}

.project_links a {
  text-decoration: underline !important;
}

/* Footer */
footer {
  display: flex;
  padding: 10px 20px;
  align-items: center;
  width: 100%;
  background-color: #0d1b2a;
  color: #fff;
}

.footer_links a {
  /* display: flex; */
  padding-right: 20px;
  color: white;
  gap: 20px;
  text-decoration: none;
}

/* Contact Us */

.aboutContainer {
  display: flex;
  flex-direction: row;
}
.aboutText {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 5rem;
  padding-left: 2rem;
  padding-right: 4rem;
  font-size: 1.5rem;
}
.aboutImage {
  display: flex;
  align-self: left;
  margin: 4rem;
  height: 25%;
  width: 25%;
}


.main-contact {
  justify-content: center;
}

/* RESUME */
.resume {
  align-content: center;
  text-decoration: none;
  color: white;
  font-size: 2rem;
  background-color: #0d1b2a;
  padding: .5rem;
  border-radius: .5rem;
  margin-left: 2rem;
}

